import BaseService from "../../../services/BaseService";
import { getClientBase } from '@/services/http'

export default class ConvocacaoService extends BaseService {
    constructor() {
        super('convocacao');
        this.path = 'convocacao';
    }

    create(dto) {
        return getClientBase().post(this.path, dto);
    }

    findPendente() {
        return getClientBase().get(this.path + '/pendentes/usuario');
    }

    buscarFuncionarios(id) {
        return getClientBase().post(this.path + '/etapa/buscar-funcionarios/' + id);
    }

    confirmarFuncionarios(id) {
        return getClientBase().post(this.path + '/etapa/funcionarios/' + id);
    }

    processarConvocacao(id, data) {
        return getClientBase().post(this.path + '/etapa/confirmacao/' + id, data);
    }

    clientes(id) {
        return getClientBase().get(this.path + '/clientes/' + id);
    }

    cancelar(id) {
        return getClientBase().post(this.path + '/cancelar/' + id);
    }
}