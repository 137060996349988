import BaseService from '../../../services/BaseService';
import { getClientBase } from '@/services/http';

export default class ConvocacaoFuncionarioService extends BaseService {
    constructor() {
        super('convocacao-funcionario');
        this.path = 'convocacao-funcionario';
    }

    async findAllFuncionario(id, { page = 1, limit = 10, filter = '', sort = '', ...otherParams }) {
        return await getClientBase().get(`${this.path}/convocacao/${id}`, {
            params: {
                page,
                limit,
                filter,
                sort,
                ...otherParams
            }
        });
    }

    async excluir(id) {
        await getClientBase().delete(`${this.path}/${id}`)
    }

    async desfazerExcluir(id) {
        await getClientBase().post(`${this.path}/desfazer-exclusao/${id}`)
    }

    async salvarMassa(id, data) {
        await getClientBase().put(`${this.path}/lote/${id}`, data)
    }
}
