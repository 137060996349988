<template lang="">
    <div class="field">
        <div class="grid formgrid mt-8">
            <div class="field col-12">
                <div class="card-container">
                    <div class="block font-bold text-center p-2 mb-3"><h1>Processando solicitação</h1></div>
                    <div class="block text-center p-4 mb-3">
                        <ProgressBar :value="+form.percentual" :showValue="false" />
                    </div>
                    <div class="block font-bold text-center mb-1">Clientes selecionados</div>
                    <div class="block text-center mb-3">
                        <div v-for="convocacaoCliente in clientes" :key="convocacaoCliente.id">
                            <i
                                v-if="convocacaoCliente.msgErro"
                                v-tooltip.bottom="convocacaoCliente.msgErro"
                                class="pi pi-info-circle"
                                :style="{ color: 'red' }"
                            ></i>
                            <span>{{ convocacaoCliente.cliente.name }}</span>
                        </div>
                    </div>
                    <div class="block font-bold text-center mb-1">Período</div>
                    <div class="block text-center mb-3">
                        {{ $filters.formatDateOnly(form.periodo) }}
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button
                            v-if="form.status=='ERRO' && form.percentual==100"
                            label="Reprocessar convocação"
                            :loading="loadingReprocessando"
                            icon="pi pi-check"
                            class="p-button p-button-primary mr-2 w-30rem"
                            @click="onClickReprocessarConvocacao"
                        />

                        <Button label="Cancelar" icon="pi pi-times" class="p-button p-button-danger" @click="showCancelarRecord = true" />

                        <AppDeleteDialog
                            v-model:visible="showCancelarRecord"
                            customTitle="Deseja realmente cancelar a convocação?"
                            labelConfirm="Cancelar"
                            @onConfirm="onClickCancelar"
                            @onClose="onCloseCancelar"
                        >
                        </AppDeleteDialog>
                    </div>
                </div>
            </div>
        </div>

        <DataTable
            dataKey="id"
            :value="records"
            filterDisplay="menu"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            selectionMode="single"
            :rowClass="defaultRowClass"
            @sort="onSort"
            sortField="id"
            :sortOrder="1"
            removableSort
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="msgErro" :style="{ padding: '1rem 0.5rem' }">
                <template #body="slotProps">
                    <i
                        v-if="slotProps.data.msgErro"
                        v-tooltip.bottom="slotProps.data.msgErro"
                        class="pi pi-info-circle"
                        :style="{ color: 'red' }"
                    ></i>
                </template>
            </Column>

            <Column field="funcionario.name" :sortable="true" sortField="funcionario.name" header="Funcionario"></Column>
            <Column field="status" :sortable="true" sortField="status" header="Status"></Column>
            <Column field="preSolicitacaoId" :sortable="true" sortField="preSolicitacaoId" header="Cód. Pré-solic gerada"></Column>
            <Column field="agendamentoId" :sortable="true" sortField="agendamentoId" header="Cód. Agend. gerado"></Column>
        </DataTable>

        <Paginator
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>
    </div>
</template>
<script>
import ConvocacaoService from '../services/ConvocacaoService';
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import EnumEtapaConvocacao from '@/enums/EnumEtapaConvocacao';
import { showSuccess, showError } from '@/utils/Toast';

export default {
    data() {
        return {
            $service: null,
            form: {},
            clientes: [],
            records: [],
            total: 0,
            page: 1,
            perPage: 10,
            sort: null,
            filter: [],
            showCancelarRecord: false,
            filtrosExtras: {
                clienteIds: null,
                unidadeIds: null,
                funcionarioIds: null,
                ignorarExcluidos: true
            },
            loadingReprocessando: false
        };
    },
    mounted() {
        this.$service = new ConvocacaoService();
        this.$serviceFuncionario = new ConvocacaoFuncionarioService();
        this.load();
        this.loadFuncionarios();
    },
    methods: {
        async load() {
            const [responseConvocacao, responseClientes] = await Promise.all([
                this.$service.findById(this.$route.params.id),
                this.$service.clientes(this.$route.params.id)
            ]);

            this.form = responseConvocacao.data;
            this.clientes = responseClientes.data;
            await this.autoLoad();
        },
        async autoLoad() {
            this.loadFuncionarios();
            const responseConvocacao = await this.$service.findById(this.$route.params.id);
            this.form = responseConvocacao.data;
            if (this.form.etapa == 'SOLICITANDO') {
                setTimeout(async () => {
                    await this.autoLoad();
                    if (this.form.etapa == EnumEtapaConvocacao.FINALIZADA) {
                        this.$router.push(`/convocacao-exames/${this.$route.params.id}/concluido`);
                    }
                }, 5000);
            }
        },
        async onClickCancelar() {
            try {
                await this.$service.cancelar(this.$route.params.id);
                showSuccess(this.$toast, 'Cancelado com sucesso');
                this.showCancelarRecord = false;
                this.$router.push(`/gestaoMedica/request`);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onCloseCancelar() {
            this.showCancelarRecord = false;
        },
        defaultRowClass(data) {
            if (data.excluido) {
                return 'row-excluida';
            }

            if (data.status == 'ERRO') {
                return 'row-erro';
            }

            if (data.status == 'FINALIZADA') {
                return 'row-finalizada';
            }

            if (data.status == 'PROCESSANDO') {
                return 'row-processando';
            }

            return '';
        },
        async loadFuncionarios() {
            try {
                const { data } = await this.$serviceFuncionario.findAllFuncionario(this.$route.params.id, {
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtras
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onSort(event) {
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.loadFuncionarios();
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.loadFuncionarios();
        },
        async onClickReprocessarConvocacao() {
            try {
                await this.$service.processarConvocacao(this.$route.params.id, this.form);
                this.$router.push(`/convocacao-exames/${this.$route.params.id}/processando-solicitacao`);
            } catch (error) {
                showError(this.$toast, error);
                this.load();
            }
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
::v-deep(.row-excluida) {
    color: #9e9e9e !important;
    text-decoration: line-through;
}
::v-deep(.row-erro) {
    color: #DC143C !important;
}
::v-deep(.row-finalizada) {
    color: #4169E1 !important;
}
::v-deep(.row-processando) {
    color: #4B0082 !important;
}
</style>
